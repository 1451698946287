import(/* webpackMode: "eager", webpackExports: ["Link"] */ "/tmp/build_2adfe956/node_modules/@chakra-ui/next-js/dist/esm/link.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Box"] */ "/tmp/build_2adfe956/node_modules/@chakra-ui/react/dist/esm/box/box.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Container"] */ "/tmp/build_2adfe956/node_modules/@chakra-ui/react/dist/esm/container/container.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["GridItem"] */ "/tmp/build_2adfe956/node_modules/@chakra-ui/react/dist/esm/grid/grid-item.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Grid"] */ "/tmp/build_2adfe956/node_modules/@chakra-ui/react/dist/esm/grid/grid.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["VStack"] */ "/tmp/build_2adfe956/node_modules/@chakra-ui/react/dist/esm/stack/v-stack.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Heading"] */ "/tmp/build_2adfe956/node_modules/@chakra-ui/react/dist/esm/typography/heading.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Text"] */ "/tmp/build_2adfe956/node_modules/@chakra-ui/react/dist/esm/typography/text.mjs");
;
import(/* webpackMode: "eager" */ "/tmp/build_2adfe956/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_2adfe956/src/app/RecaptchaProvider.tsx");
